import React from 'react';
import styled from 'styled-components';
import { fontSize, standardColours } from '../styles';
import { Container, Link } from './ui';
import { translateString } from '../utils';

const StyledSearchCategories = styled.div``;

const StyledItems = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  ${fontSize(14)}
`;

const StyledText = styled.p`
  padding-top: 10px;
`;

const StyledCategories = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const StyledCategory = styled(Link)`
  border-radius: 50px;
  padding: 10px;
  background-color: ${standardColours.white};
  color: ${standardColours.black};
`;

const SearchCategories = ({ items, locale }) =>
  items &&
  items.length > 0 && (
    <StyledSearchCategories>
      <Container>
        <StyledItems>
          <StyledText>
            {translateString('search.categoriesText', locale)}:
          </StyledText>
          <StyledCategories>
            {items.map((item, id) => (
              <StyledCategory key={id} to={item}>
                {item.title}
              </StyledCategory>
            ))}
          </StyledCategories>
        </StyledItems>
      </Container>
    </StyledSearchCategories>
  );

export default SearchCategories;
