import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TextBanner from '../components/TextBanner';
import Search from '../components/Search';
import SearchCategories from '../components/SearchCategories';
import NothingFoundMessage from '../components/NothingFoundMessage';
import ProductListing from '../components/ProductListing';
import {
  getProductCards,
  getShopifyLocaleFields,
  translateString,
} from '../utils';

const SearchPageTemplate = ({
  data: {
    datoCmsSearch,
    allDatoCmsProduct,
    allShopifyProduct,
    allDatoCmsProductCategory,
    allShopifyCollection,
  },
  location,
}) => {
  const {
    locale,
    slugLocales,
    seoMetaTags,
    title,
    nothingFoundHeading,
    nothingFoundText,
  } = datoCmsSearch;

  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState();
  const [searchCategoryResults, setSearchCategoryResults] = useState();

  useEffect(() => {
    const products = getProductCards(
      allDatoCmsProduct.nodes,
      allShopifyProduct.nodes,
      locale
    );

    setProducts(products);
  }, []);

  useEffect(() => {
    const productCategories = allDatoCmsProductCategory.nodes.map(
      datoCmsProductCategory => {
        const shopifyCollection = allShopifyCollection.nodes.find(
          ({ handle }) => handle === datoCmsProductCategory.shopifyCollection
        );

        return {
          ...datoCmsProductCategory,
          ...getShopifyLocaleFields(shopifyCollection, locale),
        };
      }
    );

    setProductCategories(productCategories);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchTerm(searchParams.get('term') || '');
  }, [location.search]);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsSearch}
    >
      <main>
        <TextBanner
          title={title}
          heading={`${
            searchResults && searchResults.length > 0
              ? `${searchResults.length} `
              : ''
          }${translateString('search.result', locale)}${
            searchResults && searchResults.length !== 1 ? 's' : ''
          } ${translateString('search.for', locale)} “${searchTerm}”`}
        >
          <Search
            products={products}
            productCategories={productCategories}
            label="Search again"
            term={searchTerm}
            setTerm={setSearchTerm}
            setResults={setSearchResults}
            setCategoryResults={setSearchCategoryResults}
            locale={locale}
            isSearchPage={true}
          />
          {searchCategoryResults !== null && (
            <SearchCategories items={searchCategoryResults} locale={locale} />
          )}
        </TextBanner>
        {searchResults === null ? (
          <NothingFoundMessage
            heading={nothingFoundHeading}
            text={nothingFoundText}
          />
        ) : (
          <ProductListing items={searchResults} />
        )}
      </main>
    </Layout>
  );
};

export const SearchPageTemplateQuery = graphql`
  query SearchPageTemplateQuery($id: String!, $locale: String!) {
    datoCmsSearch(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      nothingFoundHeading
      nothingFoundText
      ...LinkFragment
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        shopifyProduct
        ...LinkFragment
      }
    }
    allShopifyProduct {
      nodes {
        ...ShopifyProductCardFragment
        options {
          name
          values
        }
      }
    }
    allDatoCmsProductCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        shopifyCollection
        ...LinkFragment
      }
    }
    allShopifyCollection {
      nodes {
        handle
        description
        translations {
          fr {
            description: descriptionHtml
          }
        }
      }
    }
  }
`;

export default SearchPageTemplate;
