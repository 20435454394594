import React from 'react';
import styled from 'styled-components';
import { maxBreakpointQuery, sectionMargins, fontSize } from '../styles';
import { Heading, Container } from './ui';

const StyledNothingFoundMessage = styled.section`
  ${sectionMargins('80px', '160px')};
  text-align: center;
`;

const StyledText = styled.p`
  margin-top: 15px;

  ${maxBreakpointQuery.small`
    ${fontSize(15)};
  `}
`;

const NothingFoundMessage = ({ heading, text }) => (
  <StyledNothingFoundMessage>
    <Container>
      <Heading>{heading}</Heading>
      <StyledText>{text}</StyledText>
    </Container>
  </StyledNothingFoundMessage>
);

export default NothingFoundMessage;
